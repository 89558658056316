/* eslint-disable class-methods-use-this */
import _axios, { get, post, put, _delete } from '@/lin/plugin/axios'

class Information {
  // 帮助文档列表
  async getInfoList(data) {
    const res = await get('/v1/help', data)
    return res
  }

  // 新增帮助文档
  async addInfo(data) {
    const res = await post('/v1/help', data)
    return res
  }

  // 编辑帮助文档
  async editInfo(id, data) {
    const res = await put(`/v1/help/${id}`, data)
    return res
  }

  // 删除帮助文档
  async deleteInfo(id, data) {
    const res = await _delete(`/v1/help/${id}`, data)
    return res
  }
}

export default new Information()
